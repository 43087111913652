<template>
  <div class="novel-list">
    <div class="floating-icon">
      <i class="el-icon-tickets" type="primary"
         style="font-size: 50px; background-image: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%); border-radius: 10px"
         @click="catalogShow = true"></i>
    </div>
    <ul>
      <li><p class="title">{{ title }}</p></li>
      <el-divider><i class="el-icon-s-order"></i></el-divider>
      <li v-for="(item, index) in chapter.model.lines" :key="index">
        <p>{{ item }}</p>
      </li>
    </ul>

    <el-drawer
        title="目录"
        :visible.sync="catalogShow"
        direction="rtl"
        size="100%">
      <el-cascader-panel :options="catalog" ref="panel" @change="changeTree" @expand-change="expandChange"
                         v-model="cascaderVal"/>
    </el-drawer>
  </div>
</template>

<script>
import Novel from '@/request/Novel.js'

export default {
  name: "navel_see",
  components: {},
  data() {
    return {
      ask: new Novel(),
      param: {
        "force": false,
        "id": "925266579657199617",
        "offset": 0,
        "viewId": new Date().getTime()
      },
      catalogShow: true,
      catalog: [],
      title: '',
      chapter: {model: {lines: []}},
      record: null,
      cascaderVal: []
    };
  },
  mounted() {
    this.param.id = this.$route.query.id
    this.getCatalog()
    // this.getRecord()
  },
  methods: {
    getRecord(list) {
      this.ask.getRecord({"nvId": this.$route.query.id}).then(res => {
        this.cascaderVal = this.findPathByValue(list, res.nrChapter);
        this.record = res
      })
    },
    /**
     * 联级父级点击显示子级第一个
     */
    expandChange(data) {
      this.$nextTick(() => {
        // 使用 ref 引用的 $el 属性来访问 DOM 元素
        const cascaderMenu = this.$refs.panel.$el.childNodes[1].querySelectorAll('.el-cascader-node');
        // 滚动到第一个选项
        cascaderMenu[0].scrollIntoView();
      });
    },
    changeTree(data) {
      this.param.offset = 0
      this.param.id = data[data.length - 1]
      this.getNovelChapter()
    },

    /**
     * 记录阅读
     * @param nrNvId 小说id
     * @param nrChapter 章节id
     */
    insNovelRecord(title, nrNvId, nrChapter, img) {
      let param = {
        "nrTitle": title,
        "nrImg": img == null ? "" : img,
        "nrNvId": this.$route.query.id,
        "nrChapter": nrChapter
      }
      this.ask.insNovelRecord(param)
    },

    getNovelChapter() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.ask.getNovelChapter(this.param).then(res => {
        this.chapter = res
        this.title = `${res['model']['novelTitle']} - ${res['model']['volumeTitle']} - ${res['model']['title']}`
        this.catalogShow = false
        loading.close()
        this.insNovelRecord(res['model']['novelTitle'], this.$route.query.id, res['model']['id']);
      }).catch(error => {
        loading.close()
      });
    },


    /**
     * 选中联级面板数据
     * @param list
     */
    findPathByValue(data, targetValue) {
      for (const item of data) {
        // 如果当前项的 value 等于目标值，返回当前项的 value
        if (item.value === targetValue) {
          return [item.value];
        }
        // 如果当前项有 children，则递归搜索
        if (item.children) {
          const path = this.findPathByValue(item.children, targetValue);
          // 如果在子项中找到了路径，则将当前项的 value 添加到路径数组的开头
          if (path) {
            return [item.value].concat(path);
          }
        }
      }
      return null; // 如果没有找到，则返回 null
    },


    /**
     * 转换联级面板数据
     */
    catalogTransformation(list) {
      let tempList = []
      if (list != null && list.length > 0) {
        list.forEach(item => {
          let obj = this.foundCascaderData(item.title, item.id);
          if (item.children != null && item.children.length > 0) {
            let childrenList = this.catalogTransformation(item.children);
            obj['children'] = childrenList;
          }
          tempList.push(obj);
        })
      }
      return tempList;
    },

    foundCascaderData(name, id) {
      return {
        value: id,
        label: name
      }
    },

    getCatalog() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.ask.getCatalog(this.$route.query.id).then(res => {
        this.catalog = this.catalogTransformation(res.model.menus)
        this.getRecord(this.catalog)
        loading.close()
      }).catch(e => {
        loading.close()
      })

    },
  }
}
</script>

<style scoped>
.floating-icon {
  position: fixed;
  right: 30px;
  bottom: 50px;
  z-index: 1000;
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul > li {
  margin-top: 10px;
}

/deep/ .el-cascader-panel.is-bordered {
  border: none;
}

/deep/ .el-cascader-menu__wrap {
  height: 80vh;
}

.title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
</style>